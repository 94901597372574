$(function() {
    initNav();
    initSlick();
    initAanbodCarousel();
    initUspsCarousel();
    initDropdown();
    initMatchHeight();
    initObject();
    initScroll();
    initTimeline();
    initContact();
    initFaq();
    initHeaderSize();

    initObjectsOverview();
});

$(window).resize(function() {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function() {
        $nav.children('ul').attr('style', false);
        initObject();
        initHeaderSize();
    }, 200));
});

function initNav() {
    $btn_navigation = $('header .nav-toggle');
    $nav = $('header nav');

    $btn_navigation.click(function(e) {
        e.stopPropagation();
        e.preventDefault();

        if ($(window).width() < 980) {
            if ($nav.css('visibility') === 'hidden' || $nav.is(':hidden')) {
                $nav.slideDown();
                $('body').addClass('nav-open');
            } else {
                $nav.slideUp();
                $('body').removeClass('nav-open');
            }
        }
    });

    if ($nav.find('ul').length > 0) {
        $li = $nav.find('li');

        $li.has('ul').children('a').click(function(e) {

            $(this).closest('ul').children('li').find('ul').each(function(i, ul) {
                if ($(ul) != $(this).next('ul')) {
                    $(this).prev('a').children('span').removeClass('toggle');

                    if ($(window).width() < 1024) {
                        $(ul).slideUp();
                    }
                }
            });

            if ($(this).next('ul').css('visibility') === 'hidden' || $(this).next('ul').is(':hidden')) {
                e.preventDefault();

                if ($(window).width() < 1024) {
                    $(this).next('ul').slideDown();
                }
                $(this).children('span').addClass('toggle');
            }
        });
    }

    if ($(this).scrollTop() > 0) {
        $('.nav_wrapper').addClass('fixed');
        $('.phone-background').fadeOut('fast');
    } else {
        $('.nav_wrapper').removeClass('fixed');
        $('.phone-background').fadeIn('fast');
    }

    $(window).scroll(function() {
        if ($(this).scrollTop() > 0) {
            $('.nav_wrapper').addClass('fixed');
            $('.phone-background').fadeOut('fast');
        } else {
            $('.nav_wrapper').removeClass('fixed');
            $('.phone-background').fadeIn('fast');
        }
    });
}

function initSlick() {
    if ($('#header_carousel').length > 0) {
        $('#header_carousel').slick({
            dots: false,
            arrows: false,
            autoplay: true
        });
    }
}

function initAanbodCarousel() {
    if ($('#aanbod_carousel').length > 0) {
        $('#aanbod_carousel').slick({
            arrows: true,
            slidesToShow: 2,
            prevArrow: $('#homeobjects .buttons .icon-arrow-left'),
            nextArrow: $('#homeobjects .buttons .icon-arrow-right'),
            // infinite: false,
            autoplay: true,
            autoplaySpeed: 2000,
            slide: 'a',
            responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 1
                }
            }]
        });
    }

    if ($('#news_carousel').length > 0) {
        $('#news_carousel').slick({
            arrows: true,
            slidesToShow: 2,
            prevArrow: $('#news .buttons .icon-arrow-left'),
            nextArrow: $('#news .buttons .icon-arrow-right'),
            // infinite: false,
            slide: 'article',
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }]
        });
    }

    if ($('#cta-holder').length > 0) {
        $('#cta-holder .container .mob_car').slick({
            mobileFirst: true,
            arrows: false,
            slidesToShow: 1,
            infinite: false,
            slide: 'div',

            responsive: [{
                breakpoint: 768,
                settings: 'unslick'
            }]
        });
    }
}

function initUspsCarousel() {
    if ($('#usps').length > 0) {
        $('#usps').slick({
            arrows: false,
            slidesToShow: 3,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }]
        });
    }
}

function initDropdown() {
    $('.dropdown').each(function(i, el) {
        $(el).on('click', function() {
            $(el).find('ul').fadeToggle(150);

            $(el).find('a').on('click', function(e) {
                $(el).find('.value').text($(this).text());
            });
        });
    });
}

function initMatchHeight() {
    if ($('.matchheight').length > 0) {
        $('.matchheight').matchHeight();
    }

    if ($('#homepage .mob_car').length > 0 && $(window).width() < 767) {
        var height = 0;
        $('#homepage .mob_car .col-xs-12').each(function() {
            if ($(this).find('.cta').outerHeight() > height) {
                height = $(this).outerHeight();
            }
        });

        $('#homepage .mob_car .col-xs-12').each(function() {
            $(this).find('.cta').css({
                height: height
            });
        });
    }
}

function initObject() {
    if ($('#object').length > 0) {
        let top = Math.round($('#wrapper_omschrijving').position().top);
        let floater_top = top + 70;
        $(window).scroll(function() {
            clearTimeout($.data(this, 'scrollTimer'));

            newtop = Math.round((top - $(this).scrollTop()) * -1);
            maxtop = Math.round($('#wrapper_contact').position().top + $('#wrapper_contact').outerHeight() - $('.floater').outerHeight());

            if ($(this).scrollTop() > top) {
                if (maxtop > $(this).scrollTop()) {
                    $.data(this, 'scrollTimer', setTimeout(function() {
                        $('.floater').css('top', Math.min(newtop, (maxtop - floater_top)));
                    }, 30));
                } else {
                    $.data(this, 'scrollTimer', setTimeout(function() {
                        $('.floater').css('top', (maxtop - floater_top));
                    }, 30));
                }
            } else {
                $('.floater').css('top', '');
            }
        });

        // Button
        $('#omschrijving + a').on('click', function(e) {
            e.preventDefault();

            $('#omschrijving').addClass('show');
            $(this).hide();
        });

        // Lightbox
        if ($('.lightbox').length > 0) {
            $('.lightbox').magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        }

        initObjectPano();

        // Gallery icon
        $('.icon-zoom-in').on('click', function(e) {
            e.preventDefault();
            $('.lightbox').first().click();
        });
    }
}

function initObjectPano() {
    $('[id^=pano-]').each(function(index) {
        id = $(this).attr('data-id');
        data = {
            js: '/js/pano/pano.js',
            swf: '/js/pano/pano.swf',
            // xml: window.location.origin + window.location.pathname + '/pano/' + id + '.xml',
            target: 'pano-' + id,
            html5: 'prefer',
            passQueryParameters: true
        };
        embedpano(data);
    });
}

function initScroll() {
    if ($('.icon-scroll').length) {
        $('.icon-scroll').on('click', function(e) {
            e.preventDefault();
            $('html,body').stop().animate({
                scrollTop: $("#homepage").offset().top - 70
            }, 300, 'linear');
        });
    }
}

function initTimeline() {
    if ($('#overons').length) {
        $('.timeline .line').each(function() {
            var height = $(this).find('.text span p').outerHeight();
            $(this).find('.text span').css('height', height + 40);

            if ($(window).width() >= 1024) {
                $(this).find('.text').css('height', $(this).outerHeight());
                $(this).find('.image').css('height', $(this).outerHeight());
            } else {
                $(this).find('.text').css('height', height + 40);
                $(this).find('.image').css('height', '120px');
            }
        });
    }
}

function initContact() {
    // if ($('#contact').length > 0) {
    //     var latlng = new google.maps.LatLng($('#maps').data('lat'), $('#maps').data('lng'));

    //     map = new google.maps.Map(document.getElementById("maps"), {
    //         zoom: 15,
    //         mapTypeId: google.maps.MapTypeId.ROADMAP,
    //         center: latlng,
    //         scrollwheel: false
    //     });

    //     var marker = new google.maps.Marker({
    //         position: latlng,
    //         map: map,
    //         icon: '/img/icon-map.png'
    //     });
    // }
}


function initFaq() {
    if ($('#faq').length > 0) {
        if ($('.faq_wrapper h3').length > 0) {

            $('.faq_wrapper h3').on('click', function(e) {
                if ($(this).next('ul').is(':visible')) {
                    $(this).next('ul').hide();
                } else {
                    $('.questions').hide();
                    $(this).next('ul').show();

                    $('html, body').animate({
                        scrollTop: $(this).offset().top - 100
                    }, 1000);
                }
            });
        } else {
            $('.questions').show();
        }

        $('.question').on('click', function() {
            $(this).siblings().removeClass('show');
            $(this).toggleClass('show');
        });
    }
}

function initHeaderSize() {
    if ($('header').length > 0) {
        $('header #header_carousel').css({
            paddingTop: $('header .nav_wrapper').outerHeight()
        });
    }
}

function initObjectsOverview() {
    if ($('#objects').length > 0) {
        // $('#objects').css({
        //     maxHeight: 'calc(' + $('#objects').outerHeight() + 'px + ' + $('#objects .container').css('top') + ')'
        // });
    }
}